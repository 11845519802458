






























































































import { Component, Vue } from "vue-property-decorator";
import { IDivision } from "../models/Division";
import { IDistrict } from "../models/District";
import { IThana } from "../models/Thana";
import UniversalService from "../service/UniversalService";

@Component({})
export default class SearchBar extends Vue {
  public divisions: IDivision[] = [];
  public districts: IDistrict[] = [];
  public districtList: IDistrict[] = [];
  public thanas: IThana[] = [];
  public searchKey: any;
  public typeModel: any = "Doctor";
  public locationModel: any = "";
  public spModel: any = "";
  public autocompleteList: any[] = [];
  public autocompleteAllList: any[] = [];
  public rating: number = 0;
  public favourite: number = 0;
  options = [{ code: "CA", country: "Canada" }];
  public $refs: {
    type: HTMLElement;
    location: HTMLElement;
    spe: HTMLElement;
  };
  public get getRating(): number {
    this.$store.watch(
      (state) => {
        return this.$store.getters.rating;
      },
      (val) => {
        return val;
      },
      {
        deep: true,
      }
    );
    return this.$store.getters.rating;
  }

  public get getFavourite(): number {
    this.$store.watch(
      (state) => {
        return this.$store.getters.favourite;
      },
      (val) => {
        return val;
      },
      {
        deep: true,
      }
    );
    return this.$store.getters.favourite;
  }

  created() {
    UniversalService.GetAddress().then((res) => {
      this.districts = res.data.districts;
      this.districtList = res.data.districts;

      if (this.$route.query.type != undefined) {
        this.typeModel = this.$route.query.type;
      }

      if (this.$route.query.distId != undefined) {
        this.locationModel = Number(this.$route.query.distId);
      }

      if (this.$route.query.id != undefined) {
        UniversalService.autoComplete(Number(this.$route.query.id)).then((res) => {
          this.autocompleteList = res.data;
          this.spModel = this.autocompleteList.find((s) => s.id == Number(this.$route.query.id));
        });
      }
    });
  }

  change(id: any) {
    UniversalService.autoComplete(id).then((res) => {
      this.autocompleteAllList = res.data;
      this.autocompleteList = this.autocompleteAllList.filter((s) => !s.isDoctor);
    });
  }

  changeSP(e: any) {
    this.searchKey = e;
  }

  searchSpecialization(event: string) {
    if (event !== "") {
      this.autocompleteList = this.autocompleteAllList.filter((a) => a.text.toLowerCase().startsWith(event.toLowerCase()));
    } else {
      this.autocompleteList = this.autocompleteAllList.filter((s) => !s.isDoctor);
    }
  }

  enterFunction(event: string) {
    if (event !== "") {
      // console.log(event);
      this.districts = this.districtList.filter((dis) => dis.name.toLowerCase().startsWith(event.toLowerCase()));
    } else {
      this.districts = this.districtList;
    }
  }

  search() {
    if(!this.searchKey){
      if(this.spModel.id>0){
        this.searchKey=this.spModel;
      }
    }
    
    if (this.searchKey || (this.locationModel>0 && this.spModel.id>0)) {
      if (this.searchKey.isDoctor) {
        this.$router.push({
          name: "details",
          params: { id: this.searchKey.id },
        });
      } else {
        this.$router.push({
          name: "doctor-list",
          query: {
            distId: this.locationModel,
            id: this.searchKey.id,
            name: this.searchKey.text,
          },
        });
      }
    } else if (this.typeModel == "") {
      //this.$refs.type.focus();
      const select: any = this.$refs.type;
      select.$refs.search.focus();
    } else if (this.locationModel == "") {
      const location: any = this.$refs.location;
      location.$refs.search.focus();
    } else if (this.spModel == "") {
      const spes: any = this.$refs.spe;
      spes.$refs.search.focus();
    }
  }
}
