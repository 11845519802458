









































import { Component, Vue, Prop } from "vue-property-decorator";
import AuthUser from "../../global-config/AuthUser";

@Component
export default class SideNav extends Vue {
  @Prop(Boolean) private sidebarCollapseState!: boolean;
  public isDoctorRequest: boolean = AuthUser.getIsDoctorRequest();
}
