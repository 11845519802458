







































































































































































































































































































import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import AuthUser from "../../global-config/AuthUser";

@Component
export default class Header extends Vue {
  public sidebarCollapse: boolean = true;
  public isAuthorize: boolean = false;
  public isDoctorRequest: boolean = false;
  public showMobileMenu: boolean = false;
  public user = {};
  public role:any=AuthUser.getRole();

  created() {
    this.isAuthorize = AuthUser.isLoggednIn() || false;
    this.isDoctorRequest = AuthUser.getIsDoctorRequest() || false;
    this.user = AuthUser.getFullName() || {};
  }

  @Emit("emitSidebarToggle")
  sidebarToggle() {
    this.sidebarCollapse = !this.sidebarCollapse;
    return this.sidebarCollapse;
  }

  InlineButtonClickHandler() {
    return false;
  }

  logout() {
    this.isAuthorize = false;
    AuthUser.logout();
    this.$router.push("/login");
  }
}
