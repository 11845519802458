































































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { INewsLetter } from "../models/NewsLetter";
import NewsLetterService from "../service/NewsLetterService";
import AlertService from "../../common-app/service/AlertService";
import Alert from "../../common-app/components/Alert.vue";

@Component
// export default class Footer extends Vue {}
export default class NewsLetter extends Vue {
  public submited: boolean = false;
  showNewsLetterSuccess = false;
  public count: number = -1;
  public newsLetter: INewsLetter = {} as INewsLetter;

  onSubmit() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submited = true;
        NewsLetterService.GetInsert(this.newsLetter).then((res) => {
          if (res.data) {
            this.submited = false;

            this.$validator.reset();
            //alert(res.data.message);
            // AlertService.sendMessage({ status: res.data.status, message: res.data.message });
            if (res.data.status) {
              this.showNewsLetterSuccess = true;
            }
            else {
            // alert(res.data.message);
            //console.log(res.data);
            AlertService.sendMessage({ status: false, message: res.data.message });
            if(this.count <= 0){
              AlertService.sendMessage({ status: false, message: res.data.message });
            }
          }
            this.newsLetter.EmailAddress = "";
          } 
        });
      }
    });

    this.count=this.count+1;
  }
  close() {
    this.showNewsLetterSuccess = false;
  }
}
