























import { Vue, Component, Prop } from "vue-property-decorator";
import Header from "@/common-app/components/Header.vue";
import topHeader from "@/common-app/components/topHeader.vue";
import Footer from "@/common-app/components/Footer.vue";
import SearchBar from "@/common-app/components/searchBar.vue";
import SideNav from "@/common-app/components/sidenav.vue";
import Alert from "@/common-app/components/Alert.vue";
// import Chat from "@/common-app/components/Chat.vue";

@Component({
  components: {
    Header,
    Footer,
    SearchBar,
    SideNav,
    Alert,
    // Chat
  },
})
export default class MainLayout extends Vue {
  public hideSearchBar: boolean = false;
  public ourcurrentRoute:string;

  public sidebarCollapse: boolean = true;

  sidebarToggleState(state: any) {
    this.sidebarCollapse = state;
    return false;
  }
  created(): void {
    this.ourcurrentRoute = this.$router.currentRoute.path;
    if (this.ourcurrentRoute == "/notification") {
      this.hideSearchBar = true;
    }
  }
}
