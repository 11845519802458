import { UnAuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { INewsLetter } from "../models/NewsLetter";

export default {
  GetInsert(newsLetter: INewsLetter) {
        return UnAuthAxios.post(ApiURIs.NewsLetterInsert, JSON.stringify(newsLetter));
  }

//    GetApproved(id: number) {
//     return AuthAxios.get(
//       `${
//         ApiURIs.HospitalApproved
//       }?id=${id}`
//     );
//   },
//   GetDeactivate(id: number) {
//     return AuthAxios.get(
//       `${
//         ApiURIs.HospitalDeactivate
//       }?id=${id}`
//     );
//   }

};




